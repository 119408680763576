import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import WideNav from '../views/WideNav';
import NarrowNav from '../views/NarrowNav';
import ApexDefinitions from '../views/ApexDefinitions';
import FormControls from '../views/FormControls';
import HelixifyVuetifyDefinitions from '../views/HelixifyVuetifyDefinitions';
import ComplianceGraphQLMediatorAPI from '../views/ComplianceGraphQLMediatorAPI';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/wide-nav',
    name: 'WideNav',
    component: WideNav
  },
  {
    path: '/narrow-nav',
    name: 'NarrowNav',
    component: NarrowNav
  },
  {
    path: '/apex-definitions',
    name: 'apex-definitions',
    component: ApexDefinitions
  },
  {
    path: '/form-controls',
    name: 'form-controls',
    component: FormControls
  },
  {
    path: '/helixify-vuetify-definitions',
    name: 'helixify-vuetify-definitions',
    component: HelixifyVuetifyDefinitions
  },
  {
    path: '/compliance-graphql-mediator-api',
    name: 'compliance-graphql-mediator-api',
    component: ComplianceGraphQLMediatorAPI
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
