<template>
  <cs-button width="100%" @click="onExportDocx" :loading="exportLoading" label="Export DOCX" />
</template>

<script>
import {
  AlignmentType,
  BorderStyle,
  CheckBox,
  Document,
  ExternalHyperlink,
  Footer,
  Header,
  HeadingLevel,
  ImageRun,
  NumberFormat,
  Packer,
  PageBreak,
  PageNumber,
  PageOrientation,
  Paragraph,
  Table,
  TableBorders,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
  ShadingType
} from 'docx';
import { saveAs } from 'file-saver';
import { csLogoBase64Data } from '../assets/complispace-logo';

const STYLES = {
  default: {
    document: {
      run: {
        font: 'Calibri'
      }
    }
  },
  paragraphStyles: [
    {
      id: 'sampleParagraphStyle',
      name: 'Sample Paragraph Style',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        italics: true,
        color: '999999'
      },
      paragraph: {
        spacing: {
          line: 276
        },
        indent: {
          left: 720
        }
      }
    }
  ],
  characterStyles: [
    {
      id: 'sampleCharacterStyle',
      name: 'Sample Character Style',
      basedOn: 'Normal',
      run: {
        bold: true
      }
    }
  ]
};

const LOGO_IMAGE = new ImageRun({
  type: 'png',
  data: Buffer.from(csLogoBase64Data, 'base64'),
  transformation: {
    width: 100,
    height: 40
  }
});

const LINE = [
  new Paragraph({
    children: [],
    border: {
      bottom: {
        style: BorderStyle.SINGLE,
        size: 6,
        color: '000000'
      }
    },
    alignment: AlignmentType.CENTER
  }),
  new Paragraph({})
];

export default {
  name: 'ExportDocx',

  data() {
    return {
      exportLoading: false
    };
  },

  methods: {
    onExportDocx() {
      this.exportLoading = true;

      const doc = new Document({
        styles: STYLES,
        sections: [
          {
            properties: {
              page: {
                size: {
                  orientation: PageOrientation.LANDSCAPE
                },
                pageNumbers: {
                  start: 1,
                  formatType: NumberFormat.DECIMAL
                }
              },
              titlePage: true
            },
            headers: {
              default: this.pageHeader(false),
              first: this.pageHeader(true)
            },
            footers: {
              default: this.pageFooter(),
              first: this.pageFooter(true)
            },
            children: [...this.sampleTexts(), ...this.sampleTables()]
          }
        ]
      });

      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, 'sample.docx');
        this.exportLoading = false;
      });
    },
    pageHeader(firstPage = false) {
      const headerContent = firstPage
        ? LOGO_IMAGE
        : new TextRun({
            text: 'Complispace Sample.docx',
            font: 'Calibri',
            size: 28,
            color: '000000'
          });

      return new Header({
        children: [
          new Table({
            borders: TableBorders.NONE,
            width: { size: 100, type: WidthType.PERCENTAGE },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [new Paragraph({ children: [headerContent] })],
                    verticalAlign: VerticalAlign.CENTER
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            children: ['Page ', PageNumber.CURRENT]
                          }),
                          new TextRun({
                            children: [' of ', PageNumber.TOTAL_PAGES]
                          })
                        ],
                        alignment: AlignmentType.RIGHT
                      })
                    ]
                  })
                ]
              })
            ]
          })
        ]
      });
    },

    pageFooter(firstPage = false) {
      const footerContent = firstPage
        ? [
            new Paragraph({
              children: [LOGO_IMAGE],
              alignment: AlignmentType.LEFT
            })
          ]
        : [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [new TextRun('Footer content - Complispace Sample.docx')]
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({ children: ['Page Number ', PageNumber.CURRENT] }),
                new TextRun({ children: [' of ', PageNumber.TOTAL_PAGES] })
              ]
            })
          ];
      return new Footer({
        children: footerContent
      });
    },

    makeSection({ sectionTitle, sectionContent }) {
      return [
        ...this.sectionHeader(sectionTitle),
        ...sectionContent,
        new Paragraph({
          children: [new PageBreak()]
        })
      ];
    },

    sectionHeader(sectionTitle) {
      return [
        new Paragraph({
          children: [new TextRun(sectionTitle)],
          heading: HeadingLevel.HEADING_1
        }),
        ...LINE
      ];
    },

    colorShading(colorCode) {
      return {
        fill: colorCode,
        type: ShadingType.CLEAR,
        color: 'auto'
      };
    },

    sampleTexts() {
      const logoImage = Buffer.from(csLogoBase64Data, 'base64');

      const titleAndSubtitle = [
        new Paragraph({
          text: 'Professional Document Sample',
          heading: HeadingLevel.TITLE,
          alignment: AlignmentType.CENTER
        }),
        new Paragraph({
          text: 'Subtitle or Description',
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.CENTER
        })
      ];

      const paragraphStyle = [
        new Paragraph({
          text: 'Introduction',
          heading: HeadingLevel.HEADING_1,
          style: 'sampleParagraphStyle'
        })
      ];

      const highlighted = [
        new Paragraph({
          children: [
            new TextRun({
              text: 'Highlighted text',
              color: 'FFFFFF',
              highlight: 'yellow' // Yellow highlight
            })
          ]
        })
      ];

      const spaceAfter = [
        new Paragraph({
          text: 'This document demonstrates various formatting techniques used in professional documents. It includes headings, bullet points, hyperlinks, and more.',
          spacing: { after: 100 } // Space after paragraph
        })
      ];

      const headings = [
        new Paragraph({
          text: 'Heading Samples',
          heading: HeadingLevel.TITLE
        }),
        new Paragraph({
          text: 'I am heading 1',
          heading: HeadingLevel.HEADING_1
        }),
        new Paragraph({
          text: 'I am heading 2',
          heading: HeadingLevel.HEADING_2
        }),
        new Paragraph({
          text: 'I am heading 3',
          heading: HeadingLevel.HEADING_3
        }),
        new Paragraph({
          text: 'I am heading 4',
          heading: HeadingLevel.HEADING_4
        }),
        new Paragraph({
          text: 'I am heading 5',
          heading: HeadingLevel.HEADING_5
        }),
        new Paragraph({
          text: 'I am heading 6',
          heading: HeadingLevel.HEADING_6
        })
      ];

      const bullets = [
        new Paragraph({
          text: 'Bullet Samples',
          heading: HeadingLevel.TITLE
        }),
        new Paragraph({
          text: 'Key Points',
          heading: HeadingLevel.HEADING_2,
          spacing: { after: 50 }
        }),
        new Paragraph({
          text: 'Point One: Introduction',
          bullet: { level: 0 }, // Bullet point
          spacing: { after: 50 }
        }),
        new Paragraph({
          text: 'Point Two: Main Content',
          bullet: { level: 0 },
          spacing: { after: 50 }
        }),
        new Paragraph({
          text: 'Point Three: Conclusion',
          bullet: { level: 0 },
          spacing: { after: 50 }
        })
      ];

      const blockquote = [
        new Paragraph({
          text: 'Blockquotes Samples',
          heading: HeadingLevel.TITLE
        }),
        new Paragraph({
          text: 'This is a blockquote, a useful way to highlight important text.',
          style: 'sampleParagraphStyle',
          indent: { left: 720 }, // Indentation for blockquote
          alignment: AlignmentType.LEFT,
          shading: { fill: 'D3D3D3', type: ShadingType.SOLID }
        })
      ];

      const hyperlinks = [
        new Paragraph({
          text: 'Hyperlink Samples',
          heading: HeadingLevel.TITLE
        }),
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: 'Visit the docx documentation for more information.',
                  style: 'Hyperlink' // Hyperlink style
                })
              ],
              link: 'https://docx.js.org'
            })
          ],
          alignment: AlignmentType.LEFT
        })
      ];

      const alignments = [
        new Paragraph({
          text: 'Alignment Samples',
          heading: HeadingLevel.TITLE
        }),
        new Paragraph({
          text: 'Text aligned right',
          heading: HeadingLevel.HEADING_4,
          alignment: AlignmentType.RIGHT
        }),
        // Simple image with centered alignment
        new Paragraph({
          children: [
            new TextRun('Below is a centered image:'),
            new ImageRun({
              data: logoImage,
              transformation: { width: 100, height: 40 }
            })
          ],
          alignment: AlignmentType.CENTER // Centered image
        }),

        // Left-aligned image with descriptive text
        new Paragraph({
          children: [
            new TextRun('This image is left-aligned, with a brief description.'),
            new ImageRun({
              data: logoImage,
              transformation: { width: 100, height: 40 }
            })
          ],
          alignment: AlignmentType.LEFT
        }),

        // Larger image with right alignment
        new Paragraph({
          children: [
            new TextRun('Here’s a larger image aligned to the right:'),
            new ImageRun({
              data: logoImage,
              transformation: { width: 150, height: 60 }
            })
          ],
          alignment: AlignmentType.RIGHT // Right alignment for larger image
        }),

        // Image within a table cell
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE
          },
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun('Image in a table cell:'),
                        new ImageRun({
                          data: logoImage,
                          transformation: { width: 100, height: 40 }
                        })
                      ]
                    })
                  ]
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      text: 'Text adjacent to the image in the table cell.'
                    })
                  ]
                })
              ]
            })
          ]
        }),

        // Text wrapping around an image
        new Paragraph({
          children: [
            new TextRun('Text wrapping around an image demonstrates versatility in layout.'),
            new ImageRun({
              data: logoImage,
              transformation: { width: 100, height: 40 }
            }),
            new TextRun(' You can wrap text around images to create engaging content.')
          ],
          alignment: AlignmentType.LEFT // Text wrapping with left alignment
        }),

        // Image followed by a page break
        new Paragraph({
          children: [
            new TextRun('This is an image followed by a page break:'),
            new ImageRun({
              data: logoImage,
              transformation: { width: 100, height: 40 }
            }),
            new PageBreak()
          ]
        })
      ];

      return this.makeSection({
        sectionTitle: 'Sample Document Content',
        sectionContent: [
          ...titleAndSubtitle,
          new Paragraph({ spacing: { after: 200 } }),
          ...paragraphStyle,
          new Paragraph({ spacing: { after: 200 } }),
          ...highlighted,
          new Paragraph({ spacing: { after: 200 } }),
          ...spaceAfter,
          new Paragraph({ spacing: { after: 200 } }),
          ...headings,
          new Paragraph({ spacing: { after: 200 } }),
          ...bullets,
          new Paragraph({ spacing: { after: 200 } }),
          ...blockquote,
          new Paragraph({ spacing: { after: 200 } }),
          ...hyperlinks,
          new Paragraph({ children: [new PageBreak()] }),
          ...alignments
        ]
      });
    },

    sampleTables() {
      const BORDER_RED = {
        top: {
          style: BorderStyle.DASH_SMALL_GAP,
          size: 1,
          color: 'FF0000'
        },
        bottom: {
          style: BorderStyle.DASH_SMALL_GAP,
          size: 1,
          color: 'FF0000'
        },
        left: {
          style: BorderStyle.DASH_SMALL_GAP,
          size: 1,
          color: 'FF0000'
        },
        right: {
          style: BorderStyle.DASH_SMALL_GAP,
          size: 1,
          color: 'FF0000'
        }
      };
      const BORDER_BLUE = {
        top: {
          style: BorderStyle.SINGLE,
          size: 1,
          color: 'ADD8E6'
        },
        bottom: {
          style: BorderStyle.SINGLE,
          size: 1,
          color: 'ADD8E6'
        },
        left: {
          style: BorderStyle.SINGLE,
          size: 1,
          color: 'ADD8E6'
        },
        right: {
          style: BorderStyle.SINGLE,
          size: 1,
          color: 'ADD8E6'
        }
      };
      const HEADER_ROW_SHADING = this.colorShading('72A4D4');
      const ODD_ROW_SHADING = this.colorShading('ADD8E6');
      const EVEN_ROW_SHADING = this.colorShading('FFFFFF');

      const mergedCells = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE
        },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [new Paragraph('Merged 4 columns')],
                columnSpan: 4
              })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({ children: [new Paragraph({})] }),
              new TableCell({ children: [new Paragraph({})] }),
              new TableCell({ children: [new Paragraph({})] }),
              new TableCell({ children: [new Paragraph({})], borders: BORDER_RED })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({ children: [new Paragraph('1.1')] }),
              new TableCell({ children: [new Paragraph('1.2')] }),
              new TableCell({ children: [new Paragraph('1.3')] }),
              new TableCell({
                children: [new Paragraph('Merged 4 rows with different border')],
                rowSpan: 4,
                borders: BORDER_RED
              })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({ children: [new Paragraph('2.1')] }),
              new TableCell({ children: [new Paragraph('2,2')] }),
              new TableCell({
                children: [new Paragraph('Merged 3 rows')],
                rowSpan: 3
              })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({ children: [new Paragraph('3.1')] }),
              new TableCell({
                children: [new Paragraph('Merged 2 rows')],
                rowSpan: 2
              })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new ImageRun({
                        type: 'png',
                        data: Buffer.from(csLogoBase64Data, 'base64'),
                        transformation: {
                          width: 100,
                          height: 40
                        }
                      })
                    ],
                    alignment: AlignmentType.CENTER
                  })
                ]
              })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [new Paragraph('Bar1')],
                shading: {
                  fill: 'b79c2f',
                  type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                  color: 'auto'
                }
              }),
              new TableCell({
                children: [new Paragraph('Bar2')],
                shading: {
                  fill: '42c5f4',
                  type: ShadingType.PERCENT_95,
                  color: 'auto'
                }
              }),
              new TableCell({
                children: [new Paragraph('Bar3')],
                shading: {
                  fill: '880aa8',
                  type: ShadingType.PERCENT_10,
                  color: 'e2df0b'
                }
              }),
              new TableCell({
                children: [new Paragraph('Bar4')],
                shading: {
                  fill: 'FF0000',
                  type: ShadingType.CLEAR,
                  color: 'auto'
                }
              })
            ]
          })
        ]
      });

      const checkboxes = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE
        },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                width: { size: 10, type: WidthType.PERCENTAGE },
                children: [
                  new Paragraph({
                    children: [new CheckBox({ checked: true })],
                    alignment: AlignmentType.CENTER
                  })
                ],
                shading: ODD_ROW_SHADING,
                borders: BORDER_BLUE
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: ' Item 1'
                  })
                ],
                borders: BORDER_BLUE
              })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                width: { size: 10, type: WidthType.PERCENTAGE },
                children: [
                  new Paragraph({
                    children: [new CheckBox({ checked: true })],
                    alignment: AlignmentType.CENTER
                  })
                ],
                shading: EVEN_ROW_SHADING,
                borders: BORDER_BLUE
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: ' Item 2'
                  })
                ],
                borders: BORDER_BLUE
              })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                width: { size: 10, type: WidthType.PERCENTAGE },
                children: [
                  new Paragraph({
                    children: [new CheckBox({ checked: false })],
                    alignment: AlignmentType.CENTER
                  })
                ],
                shading: ODD_ROW_SHADING,
                borders: BORDER_BLUE
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: ' Item 3'
                  })
                ],
                borders: BORDER_BLUE
              })
            ]
          })
        ]
      });

      const rowsData = [
        {
          riskRating: 'Almost Certain',
          description: 'Will almost certainly happen during the course of an excursion.'
        },
        {
          riskRating: 'Likely',
          description: 'May occur during the course of an excursion.'
        },
        {
          riskRating: 'Possible',
          description: 'Has a chance of occurring during the course of an excursion.'
        },
        {
          riskRating: 'Unlikely',
          description: 'Not likely to occur during the course of an excursion.'
        },
        {
          riskRating: 'Rare',
          description:
            'Will only occur in exceptional circumstances (would be considered highly unusual).'
        }
      ];
      const rowsColor = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE
        },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'Risk Rating',
                    alignment: AlignmentType.CENTER
                  })
                ],
                shading: HEADER_ROW_SHADING,
                borders: BORDER_BLUE
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'Description',
                    alignment: AlignmentType.CENTER
                  })
                ],
                shading: HEADER_ROW_SHADING,
                borders: BORDER_BLUE
              })
            ]
          }),
          ...rowsData.map((row, index) => {
            return new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      text: row.riskRating
                    })
                  ],
                  shading: index % 2 ? EVEN_ROW_SHADING : ODD_ROW_SHADING,
                  borders: BORDER_BLUE
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      text: row.description
                    })
                  ],
                  shading: index % 2 ? EVEN_ROW_SHADING : ODD_ROW_SHADING,
                  borders: BORDER_BLUE
                })
              ]
            });
          })
        ]
      });

      const EXTREME_RISK_CELL = {
        text: 'E',
        shading: this.colorShading('FF1837')
      };
      const HIGH_RISK_CELL = {
        text: 'H',
        shading: this.colorShading('FF9A00')
      };
      const MEDIUM_RISK_CELL = {
        text: 'M',
        shading: this.colorShading('FDFF32')
      };
      const LOW_RISK_CELL = {
        text: 'L',
        shading: this.colorShading('1BFC06')
      };
      const riskAssessmentGuideRowsData = [
        [
          {
            text: 'Almost Certain',
            shading: EVEN_ROW_SHADING
          },
          MEDIUM_RISK_CELL,
          MEDIUM_RISK_CELL,
          HIGH_RISK_CELL,
          EXTREME_RISK_CELL,
          EXTREME_RISK_CELL
        ],
        [
          {
            text: 'Likely',
            shading: ODD_ROW_SHADING
          },
          LOW_RISK_CELL,
          MEDIUM_RISK_CELL,
          HIGH_RISK_CELL,
          EXTREME_RISK_CELL,
          EXTREME_RISK_CELL
        ],
        [
          {
            text: 'Possible',
            shading: EVEN_ROW_SHADING
          },
          LOW_RISK_CELL,
          MEDIUM_RISK_CELL,
          MEDIUM_RISK_CELL,
          HIGH_RISK_CELL,
          EXTREME_RISK_CELL
        ],
        [
          {
            text: 'Unlikely',
            shading: ODD_ROW_SHADING
          },
          LOW_RISK_CELL,
          LOW_RISK_CELL,
          MEDIUM_RISK_CELL,
          MEDIUM_RISK_CELL,
          HIGH_RISK_CELL
        ],
        [
          {
            text: 'Rare',
            shading: EVEN_ROW_SHADING
          },
          LOW_RISK_CELL,
          LOW_RISK_CELL,
          LOW_RISK_CELL,
          MEDIUM_RISK_CELL,
          HIGH_RISK_CELL
        ]
      ];
      const sideBySide = new Table({
        borders: TableBorders.NONE,
        width: { size: 100, type: WidthType.PERCENTAGE },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                width: { size: 50, type: WidthType.PERCENTAGE },
                children: [
                  new Paragraph({
                    text: 'Risk Assessment Guide',
                    heading: HeadingLevel.HEADING_1
                  }),
                  new Paragraph({
                    text: 'An overall risk rating of the residual risk is assessed for each identified risk using the following risk matrix and the definitions of likelihood and consequence. The overall rating is determined by the combination of likelihood and consequence as plotted on the matrix. Residual risk is the level of risk that remains after controls have been applied. The risk matrix has four levels of risk rating:'
                  }),
                  new Paragraph({
                    children: [new TextRun('E'), new TextRun(' = Extreme')]
                  })
                ]
              }),
              // Nested table in one of the cells
              new TableCell({
                width: { size: 50, type: WidthType.PERCENTAGE },
                children: [
                  new Table({
                    width: { size: 100, type: WidthType.PERCENTAGE },
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [new Paragraph('Likelihood')],
                            shading: HEADER_ROW_SHADING
                          }),
                          new TableCell({
                            children: [new Paragraph('Consequences')],
                            columnSpan: 5,
                            shading: HEADER_ROW_SHADING
                          })
                        ]
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [new Paragraph('')],
                            shading: ODD_ROW_SHADING
                          }),
                          new TableCell({
                            children: [new Paragraph('Insignificant')],
                            shading: ODD_ROW_SHADING
                          }),
                          new TableCell({
                            children: [new Paragraph('Minor')],
                            shading: ODD_ROW_SHADING
                          }),
                          new TableCell({
                            children: [new Paragraph('Moderate')],
                            shading: ODD_ROW_SHADING
                          }),
                          new TableCell({
                            children: [new Paragraph('Major')],
                            shading: ODD_ROW_SHADING
                          }),
                          new TableCell({
                            children: [new Paragraph('Catastrophic')],
                            shading: ODD_ROW_SHADING
                          })
                        ]
                      }),
                      ...riskAssessmentGuideRowsData.map((row) => {
                        const cells = row.map((cell) => {
                          return new TableCell({
                            children: [
                              new Paragraph({
                                text: cell.text,
                                alignment: AlignmentType.CENTER
                              })
                            ],
                            shading: cell.shading
                          });
                        });
                        return new TableRow({ children: cells });
                      })
                    ]
                  })
                ]
              })
            ]
          })
        ]
      });

      return this.makeSection({
        sectionTitle: 'Sample Tables',
        sectionContent: [
          new Paragraph({
            text: 'Table Samples',
            heading: HeadingLevel.TITLE,
            spacing: { after: 200 }
          }),
          new Paragraph({
            text: 'Table below shows that the cells can be merged in rows and merged in columns. The cell can also contains image.',
            heading: HeadingLevel.HEADING_6,
            spacing: { after: 200 }
          }),
          mergedCells,
          new Paragraph({
            text: 'Table below shows that the cells can have checkboxes.',
            heading: HeadingLevel.HEADING_6,
            spacing: { before: 200, after: 200 }
          }),
          checkboxes,
          new Paragraph({
            text: 'Table below shows the alternate rows color.',
            heading: HeadingLevel.HEADING_6,
            spacing: { before: 200, after: 200 }
          }),
          rowsColor,
          new Paragraph({ children: [new PageBreak()] }),
          new Paragraph({
            text: 'Table below shows table and text side by side.',
            heading: HeadingLevel.HEADING_6,
            spacing: { before: 200, after: 200 }
          }),
          sideBySide
        ]
      });
    }
  }
};
</script>

<style scoped></style>
