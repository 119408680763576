<template>
  <div width="100%">
    <div>
      <div>Selected Illustration:</div>
      <div class="my-3" style="display: flex; align-items: center; justify-content: center">
        <div class="mx-3"><img height="80px" :src="encodeURI(selectedImg)" alt="" /></div>
        <div class="mb-3">{{ selectedImg }}</div>
      </div>
      <cs-button
        id="btn-show-dialog"
        primary
        @click="onShowFormDialogClick"
        label="Show in Form"
      ></cs-button>
      <cs-button
        id="btn-reset-illustration"
        primary
        @click="onResetIllustration"
        label="Reset"
        class="ml-2"
      ></cs-button>
      <cs-illustration-selector
        id="illustration-selector"
        v-model="selectedImg"
        path=""
        @change="onIllustrationChange"
        class="mt-2"
      ></cs-illustration-selector>
      <cs-illustration-selector
        id="illustration-selector3"
        v-model="selectedImg"
        path="Users"
        @change="onIllustrationChange"
        class="mt-2"
      ></cs-illustration-selector>
    </div>

    <cs-form-dialog
      v-model="showFormDialog"
      id="form-dialog-1"
      heading="CSIllustration Selector in Form"
      :primaryAction="{ label: 'Ok', eventName: 'dlg-ok' }"
      :secondaryAction1="{
        label: 'Cancel',
        eventName: 'dlg-cancel'
      }"
      @dlg-ok="onDlgOkClick"
      @dlg-cancel="showFormDialog = false"
    >
      <template v-slot:cs-form-dialog-content>
        <cs-illustration-selector
          id="illustration-selector-in-form"
          v-model="dlgSelectedImg"
          path=""
        ></cs-illustration-selector>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import { CSFormDialog, CSIllustrationSelector } from '@complispace/cs-design-system';

export default {
  name: 'CSIllustrationSelectorTest',

  components: {
    'cs-illustration-selector': CSIllustrationSelector,
    'cs-form-dialog': CSFormDialog
  },

  data() {
    return {
      showFormDialog: false,
      selectedImg: '/illustrations/Users/Learners.svg',
      dlgSelectedImg: '/illustrations/Users/Learners.svg'
    };
  },
  created() {},
  methods: {
    onIllustrationChange(newImg) {
      // log for for demo purpose
      console.log('onIllustrationChange', newImg);
    },
    onShowFormDialogClick() {
      this.dlgSelectedImg = this.selectedImg;
      this.showFormDialog = true;
    },
    onResetIllustration() {
      this.selectedImg = '';
    },
    onDlgOkClick() {
      this.showFormDialog = false;
      this.selectedImg = this.dlgSelectedImg;
      this.dlgSelectedImg = '';
    }
  }
};
</script>
<style scoped>
.buttons-row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.colorDefinition {
  border: 1px solid gray;
  width: 100%;
}
.colorDesc {
  min-width: 50px;
  color: white;
  font-size: 12px;
  text-align: center;
}
.colorPill {
  width: 100px;
  height: 50px;
  font-size: 10pt;
  border: 1px solid gray;
}
</style>
