<template>
  <cs-view single-view>
    <template v-slot:content>
      <v-container fluid>
        <v-row align="center" justify="center">
          <h1>Compliance GraphQL Mediator GraphQL API</h1>
        </v-row>
        <v-row class="pt-4">
          <v-col>
            <cs-button @click="onExportPDf" label="Export PDF" />
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col>
            <v-progress-linear
              v-if="showAsyncInProgress"
              indeterminate
              rounded
              height="25"
            ></v-progress-linear>
          </v-col>
        </v-row>
        <v-row align="center" class="pt-4" justify="center">
          <v-col v-if="pdf">
            <v-card>
              Result for query pdf by(name: "foobar.pdf")
              <json-editor-vue id="query" v-model="pdf" mode="tree" readOnly></json-editor-vue>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col>
            <v-card>
              <json-editor-vue id="pdf-sample" v-model="pdfSample" mode="tree"></json-editor-vue>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </cs-view>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters, mapMutations, mapState } from 'vuex';
import JsonEditorVue from 'json-editor-vue';
import * as types from '../store/mutationTypes';
import pdfRawSample from '../store/modules/compliance-graphql-mediator/pdf-sample.html';
import { DateTime } from '../infrastructure/DateTime';

export default {
  name: 'ComplianceGraphQLMediatorAPI',

  components: { JsonEditorVue },

  extends: CSBase,

  data() {
    return {
      pdf: undefined,
      showAsyncInProgress: false,
      subscriptionOnCreatePDFPending: null,
      subscriptionOnCreatePDFReady: null,
      pdfSample: pdfRawSample
    };
  },

  async mounted() {
    const self = this;
    this.setPageViewType('compliance-graphql-mediator-api');

    this.subscriptionOnCreatePDFPending = await this.$store.dispatch(
      'compliance-graphql-mediator/subscribeOnCreatePDF',
      {
        id: 'mutated-foobar-id-1',
        status: 'pending',
        next: () => {
          self.showAsyncInProgress = true;
        }
      }
    );

    this.subscriptionOnCreatePDFReady = await this.$store.dispatch(
      'compliance-graphql-mediator/subscribeOnCreatePDF',
      {
        id: 'mutated-foobar-id-1',
        status: 'ready',
        next: async ({ data }) => {
          if (process.env.VUE_APP_APP_ENV === 'development') {
            self.showWarningAlert(
              'Unable to download pdf from cloudfront so if you see file was not available on site, please execute shell command aws s3 cp --recursive s3://compliance-generated-pdfs-dev/pdf/download/ ./public/pdf/download --profile <your profile>\n and export again on local development environment.'
            );
          }

          const link = document.createElement('a');
          link.download = `${data.onCreatePDF.id}.pdf`;
          link.href = `/${data.onCreatePDF.location}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.clearLoading();
          self.showAsyncInProgress = false;
        }
      }
    );

    this.pdf = await this.$store.dispatch('compliance-graphql-mediator/queryGetPDF', 'foobar-id-1');
  },

  beforeDestroy() {
    this.subscriptionOnCreatePDFPending.unsubscribe();
    this.subscriptionOnCreatePDFReady.unsubscribe();
  },

  methods: {
    ...mapMutations({
      setPageViewType: types.VIEW_TYPE_SET_PAGE_VIEW_TYPE
    }),

    async onExportPDf() {
      this.setLoading(true);
      const now = DateTime();
      const nowStr = now.stringify('Australia/Sydney', 'DD/MM/YYYY HH:mm');

      await this.$store.dispatch('compliance-graphql-mediator/mutateCreatePDF', {
        id: 'mutated-foobar-id-1',
        name: 'new-foobar',
        content: this.pdfSample,
        headerContent: `This document was current as at ${nowStr} AEST time. Updates to content may have been made since this date. Refer to your Policy site for the latest version.`,
        footerContent: `This content was last updated on ${nowStr} AEST time.`
      });
    }
  },

  computed: {
    ...mapGetters({}),
    ...mapState({
      viewType: 'view-type'
    })
  }
};
</script>

<style>
.jse-menu {
  background-color: var(--v-primary-lighten1) !important;
}
</style>
<style scoped>
.editor-textarea {
  border-style: dotted;
  border-width: 1px;
}

#pdf-template,
#pdf-content {
  height: 600px;
}
</style>
