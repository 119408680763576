<template>
  <v-card class="typography">
    <v-card-title>Apex Typography</v-card-title>
    <div>
      <div class="tagDefinition">
        <div class="styleName">
          body
          <div class="styleProps">{{ fontProperties('#typography-body-sample') }}</div>
        </div>
        <div id="typography-body-sample">Sample of body text</div>
      </div>
      <div class="tagDefinition">
        <div class="styleName">
          p
          <div class="styleProps">{{ fontProperties('#typography-p-sample') }}</div>
        </div>
        <p id="typography-p-sample">Sample of paragraph</p>
      </div>
      <div class="tagDefinition">
        <div class="styleName">
          h1
          <div class="styleProps">{{ fontProperties('#typography-h1-sample') }}</div>
        </div>
        <h1 id="typography-h1-sample">Show mangled quartz flip vibe exactly</h1>
      </div>
      <div class="tagDefinition">
        <div class="styleName">
          h2
          <div class="styleProps">{{ fontProperties('#typography-h2-sample') }}</div>
        </div>
        <h2 id="typography-h2-sample">Show mangled quartz flip vibe exactly</h2>
      </div>
      <div class="tagDefinition">
        <div class="styleName">
          h3
          <div class="styleProps">{{ fontProperties('#typography-h3-sample') }}</div>
        </div>
        <h3 id="typography-h3-sample">Show mangled quartz flip vibe exactly</h3>
      </div>
      <div class="tagDefinition">
        <div class="styleName">
          caption
          <div class="styleProps">{{ fontProperties('#typography-caption-sample') }}</div>
        </div>
        <caption id="typography-caption-sample">
          Show mangled quartz flip vibe exactly
        </caption>
      </div>
      <div class="tagDefinition">
        <div class="styleName">
          a
          <div class="styleProps">{{ fontProperties('#typography-a-sample') }}</div>
        </div>
        <a id="typography-a-sample" href="#"> Show mangled quartz flip vibe exactly </a>
      </div>
      <div class="tagDefinition">
        <div class="styleName">
          label
          <div class="styleProps">{{ fontProperties('#typography-label-sample') }}</div>
        </div>
        <label id="typography-label-sample"> Show mangled quartz flip vibe exactly </label>
      </div>
      <div class="tagDefinition">
        <div class="styleName">
          input
          <div class="styleProps">{{ fontProperties('#typography-input-sample') }}</div>
        </div>
        <input id="typography-input-sample" value="Show mangled quartz flip vibe exactly" />
      </div>
      <div class="tagDefinition">
        <div class="styleName">
          time
          <div class="styleProps">{{ fontProperties('#typography-time-sample') }}</div>
        </div>
        <div><time id="typography-time-sample">10:00</time> to <time>21:00</time></div>
      </div>
    </div>
    <template v-for="(value, name) in $style">
      <div class="styleDefinition" :key="name">
        <div class="styleName">
          {{ '.' + name }}
          <div class="styleProps">{{ fontProperties(`.${value}`) }}</div>
        </div>
        <v-chip large class="ma-2" label outlined pill color="black">
          <div class="pt-2 pb-0" style="min-width: 165px">
            <div :class="value">The quick brown fox jumps over the lazy dog</div>
          </div>
        </v-chip>
      </div>
    </template>
  </v-card>
</template>

<script>
export default {
  components: {},
  name: 'CSTypography',
  props: {
    title: { type: String, require: true },
    colorDefinitions: { type: Object, require: true }
  },
  data() {
    return { loaded: false };
  },
  computed: {
    fontProperties() {
      if (this.loaded) {
        return (selector) => this.getFontProperties(selector);
      }
      return () => '';
    }
  },
  mounted() {
    this.loaded = true;
  },
  methods: {
    getFontProperties(selector) {
      const element = document.querySelector(selector);
      if (element) {
        const cssObj = window.getComputedStyle(element, null);
        const fontWeight = cssObj.getPropertyValue('font-weight');
        const lineHeight = cssObj.getPropertyValue('line-height');
        const fontSize = cssObj.getPropertyValue('font-size');
        const props = `weight: ${fontWeight}, size: ${fontSize}, line-height: ${lineHeight}`;
        return props;
      }
      return '';
    }
  }
};
</script>
<style scoped src="@complispace/cs-apex-definitions/dist/css/typography.css" module></style>
<style scoped>
.typography {
  border: 2px solid #f4f5f6;
  border-radius: 4px;
}
.tagDefinition {
  display: inline-flex;
  background-color: white;
  min-width: 100%;
  align-items: center;
  margin-bottom: 10px;
}
.styleDefinition {
  display: inline-flex;
  background-color: white;
  min-width: 750px;
  align-items: center;
}
.styleName {
  min-width: 220px;
  color: black;
  font-size: 10pt;
  padding-left: 10px;
}
.styleProps {
  color: gray;
  font-size: 8pt;
}
</style>
