<template>
  <div width="100%">
    <div class="buttons-row">
      <div class="ma-1">
        <cs-button id="btn-click-me" primary @click="onClickMe" label="Click Me"></cs-button>
      </div>
      <div class="ma-2 primary--text">Clicks {{ noOfClicks }}</div>
    </div>
    <div class="buttons-row">
      <div class="ma-1">
        <cs-button
          :disabled="disabled"
          large
          primary
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          primary
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          small
          primary
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button large primary leftIcon="mdi-star" label="Left Icon"></cs-button>
      </div>
      <div class="ma-1">
        <cs-button primary rightIcon="check_circle" label="Right Icon"></cs-button>
      </div>
      <div class="ma-1">
        <cs-button small primary label="Text only"></cs-button>
      </div>
    </div>
    <div class="buttons-row">
      <div class="ma-1">
        <cs-button
          large
          :disabled="disabled"
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          small
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button large leftIcon="mdi-star" label="Left Icon"></cs-button>
      </div>
      <div class="ma-1">
        <cs-button rightIcon="check_circle" label="Right Icon"></cs-button>
      </div>
      <div class="ma-1">
        <cs-button small label="Text only"></cs-button>
      </div>
    </div>
    <div class="buttons-row">
      <div class="ma-1">
        <cs-button
          large
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          disabled
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          disabled
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          small
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          disabled
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button large disabled leftIcon="mdi-star" label="Left Icon"></cs-button>
      </div>
      <div class="ma-1">
        <cs-button disabled rightIcon="check_circle" label="Right Icon"></cs-button>
      </div>
      <div class="ma-1">
        <cs-button small disabled label="Text only"></cs-button>
      </div>
    </div>
    <div class="buttons-row">
      <div class="ma-1">
        <cs-button
          large
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          delete
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          delete
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          small
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          delete
          label="Button"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button large delete leftIcon="mdi-star" label="Left Icon"></cs-button>
      </div>
      <div class="ma-1">
        <cs-button delete rightIcon="check_circle" label="Right Icon"></cs-button>
      </div>
      <div class="ma-1">
        <cs-button small delete label="Text only"></cs-button>
      </div>
    </div>
    <div class="buttons-row">
      <div class="ma-1">
        <cs-button
          large
          primary
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          label="Loader"
          @click="onClickLoad"
          :loading="loading"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          primary
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          label="Loader"
          @click="onClickLoad"
          :loading="loading"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          primary
          small
          leftIcon="mdi-plus-circle-outline"
          rightIcon="mdi-plus-circle-outline"
          label="Loader"
          @click="onClickLoad"
          :loading="loading"
        ></cs-button>
      </div>
    </div>
    <div class="buttons-row" style="background-color: RGB(245, 245, 245)">
      <div class="ma-1">
        <cs-button information title="Title only"></cs-button>
      </div>
      <div class="ma-1">
        <cs-button information title="Title" description="Description"></cs-button>
      </div>
      <div class="ma-1">
        <cs-button information title="Title" :description="longDescription"></cs-button>
      </div>
    </div>
    <div class="buttons-row" style="background-color: RGB(245, 245, 245)">
      <div class="ma-1">
        <cs-button information title="Title only" leftIcon="mdi-file-video"></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          information
          leftIcon="mdi-file-video"
          title="Title"
          description="Description"
        ></cs-button>
      </div>
      <div class="ma-1">
        <cs-button
          information
          leftIcon="mdi-file-video"
          title="Title"
          :description="longDescription"
        ></cs-button>
      </div>
    </div>
    <div class="mt-2 colorDefinition">
      <div class="pa-1 buttons-row">
        <div>Button Colors</div>
      </div>
      <div style="display: flex; flex-wrap: wrap">
        <div class="colorPill ma-1" :style="{ backgroundColor: currentTheme.primary.base }">
          <div class="colorDesc">
            <div class="colorText">Default/Focus</div>
            <span class="colorCode">{{ currentTheme.primary.base }}</span>
          </div>
        </div>
        <div class="colorPill ma-1" :style="{ backgroundColor: currentTheme.primary.lighten1 }">
          <div class="pb-0 colorDesc">
            <div class="colorText">Hover</div>
            <span class="colorCode">{{ currentTheme.primary.lighten1 }}</span>
          </div>
        </div>
        <div class="colorPill ma-1" :style="{ backgroundColor: currentTheme.primary.darken1 }">
          <div class="pb-0 colorDesc">
            <div class="colorText">Pressed</div>
            <span class="colorCode">{{ currentTheme.primary.darken1 }}</span>
          </div>
        </div>
        <div class="colorPill ma-1" :style="{ backgroundColor: currentTheme.secondary.base }">
          <div class="pb-0 colorDesc black--text text--lighten-2">
            <div class="colorText">Default/Focus</div>
            <span class="colorCode">{{ currentTheme.secondary.base }}</span>
          </div>
        </div>
        <div class="colorPill ma-1" :style="{ backgroundColor: currentTheme.secondary.darken1 }">
          <div class="pb-0 colorDesc black--text text--lighten-2">
            <div class="colorText">Hover/Disabled</div>
            <span class="colorCode">{{ currentTheme.secondary.darken1 }}</span>
          </div>
        </div>
        <div class="colorPill ma-1" :style="{ backgroundColor: currentTheme.secondary.darken2 }">
          <div class="pb-0 colorDesc black--text text--lighten-2">
            <div class="colorText">Pressed</div>
            <span class="colorCode">{{ currentTheme.secondary.darken2 }}</span>
          </div>
        </div>
        <div class="colorPill ma-1" :style="{ backgroundColor: currentTheme.btnDelete }">
          <div class="pb-0 colorDesc white--text">
            <div class="colorText">Delete Default</div>
            <span class="colorCode">{{ currentTheme.btnDelete }}</span>
          </div>
        </div>
        <div class="colorPill ma-1" :style="{ backgroundColor: currentTheme.btnDeleteHover }">
          <div class="pb-0 colorDesc white--text">
            <div class="colorText">Delete Hover</div>
            <span class="colorCode">{{ currentTheme.btnDeleteHover }}</span>
          </div>
        </div>
        <div class="colorPill ma-1" :style="{ backgroundColor: currentTheme.btnDeletePress }">
          <div class="pb-0 colorDesc white--text">
            <div class="colorText">Delete Pressed</div>
            <span class="colorCode">{{ currentTheme.btnDeletePress }}</span>
          </div>
        </div>
        <div class="colorPill ma-1" :style="{ backgroundColor: currentTheme.secondary.darken3 }">
          <div class="pb-0 colorDesc white--text">
            <div class="colorText">Info Border</div>
            <span class="colorCode">{{ currentTheme.secondary.darken3 }}</span>
          </div>
        </div>
        <div class="colorPill ma-1" :style="{ backgroundColor: currentTheme.black.lighten5 }">
          <div class="pb-0 colorDesc white--text">
            <div class="colorText">Info Pressed</div>
            <span class="colorCode">{{ currentTheme.black.lighten5 }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CSButtonTest',

  data() {
    return {
      noOfClicks: 0,
      disabled: false,
      loading: false,
      longDescription:
        'This is a long description that should have lines more than 2. This is used to test information buttons with longer that a line'
    };
  },

  methods: {
    onClickMe() {
      this.noOfClicks += 1;
      this.disabled = !this.disabled;
    },
    onClickLoad() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 3000);
    }
  },

  computed: {
    currentTheme() {
      if (this.$vuetify.theme.isDark) {
        return this.$vuetify.theme.themes.dark;
      }
      return this.$vuetify.theme.themes.light;
    }
  }
};
</script>
<style scoped>
.buttons-row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.colorDefinition {
  border: 1px solid gray;
  width: 100%;
}
.colorDesc {
  min-width: 50px;
  color: white;
  font-size: 12px;
  text-align: center;
}
.colorPill {
  width: 100px;
  height: 50px;
  font-size: 10pt;
  border: 1px solid gray;
}
</style>
