import Vue from 'vue';
import CSDesignSystem, {
  vuetifyBuilder,
  i18n,
  overrideLoginTranslations
} from '@complispace/cs-design-system';
import JsonEditorVue from 'json-editor-vue';
import App from './App';
import router from './router';
import store from './store';
import SlotIndicator from './components/SlotIndicator';

Vue.config.productionTip = false;

const options = {
  store,
  router,
  auth0: {
    appHost: window.location.host,
    appUrl: window.location.origin,
    auth0Domain: process.env.VUE_APP_AUTH0_DOMAIN,
    auth0ClientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    auth0Audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    appEnv: process.env.VUE_APP_APP_ENV || 'develop'
  },
  gainsight: {
    gainsightSiteInfoApiHost: process.env.VUE_APP_GAINSIGHT_SITE_INFO_API_HOST,
    gainsightProductTag: process.env.VUE_APP_GAINSIGHT_PRODUCT_TAG
  },
  login: {
    resetBtnTimeout: parseInt(process.env.VUE_APP_LOGIN_RESET_TIMEOUT || 5, 10)
  }
};
// Complispace design system
Vue.use(CSDesignSystem, options);

Vue.use(JsonEditorVue, {
  // global props & attrs (one-way data flow)
});

// Internationalization
const { messages } = i18n;
Object.keys(messages).forEach((key) => {
  const appHostSplits = window.location.host.split('.');
  const combined = messages[key];
  const overridden = overrideLoginTranslations(combined, {
    loginViewSubTitle: `Log in to ${
      appHostSplits[0].toUpperCase() || ''
    } CS Design System Documentation Site below`
  });

  i18n.setLocaleMessage(key, overridden);
});

Vue.component('slot-indicator', SlotIndicator);

const vuetify = vuetifyBuilder({}).build();

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
